@import url("https://fonts.googleapis.com/css?family=Pixelify+Sans&display=swap");

.button-and-result {
  text-align: center;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 20px 0 50px;

  .type-submit {
    background-color: #ffffff;
    border: 1px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 3px 3px 0 0;
    color: #422800;
    font-family: "Pixelify Sans";
    font-size: 22px;
    font-weight: bold;
    margin: auto;
    padding: 0 18px;
    line-height: 50px;
    text-align: center;

    &:hover {
      background-color: #fbe0e0;
    }

    &:active {
      box-shadow: #422800 0px 0px 0 0;
      transform: translate(3px, 3px);
    }
  }

  .type-result {
    margin: auto;
    font-size: 28px;
  }
}
