@import url("https://fonts.googleapis.com/css?family=Pixelify+Sans&display=swap");

body {
  margin: 0px 150px;
  font-family: "Pixelify Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "VT323";
}
