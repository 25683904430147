.type-question {
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin: 50px 0 20px;
}

.type-answer {
  display: grid;
  font-size: 16px;
  grid-template-columns: 20% 20% 20% 20% 20%;

  .type-answer-image {
    height: 75px;
    width: 75px;
    cursor: pointer;
    filter: brightness(2.5) grayscale(1) opacity(0.75);

    &:hover {
      filter: brightness(1.2) grayscale(0.65) opacity(0.9);
    }
  }

  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  [type="radio"]:checked + img {
    filter: none;
  }
}
